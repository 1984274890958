import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import PasswordForm from "./PasswordForm";

import type { MouseEvent } from "react";

export interface AuthCardProps {
  formOption: "signup" | "login";
  children?: React.ReactNode;
}

export default function AuthCard({ formOption, children }: AuthCardProps) {
  const onButtonClick = async (e: MouseEvent) => {
    e.preventDefault();
    const target = e.target as HTMLButtonElement;
    if (target.value !== "") {
      const formData = new FormData();
      formData.append("email", "");
      formData.append("password", "");
      formData.append("provider", target.value);

      const res = await fetch("/api/auth/signin", {
        method: "POST",
        body: formData,
      });

      if (res.status === 200) {
        window.location.replace(await res.text());
      }
    }
  };

  return (
    <Card className="md:w-[400px] w-full md:h-fit h-screen">
      <CardHeader>{children}</CardHeader>
      <CardContent>
        <PasswordForm formOption={formOption} />
      </CardContent>
      <CardFooter className="flex justify-between flex-col">
        <div className="relative flex py-5 items-center w-1/2 mx-auto">
          <div className="flex-grow border-t border-muted-foreground"></div>
          <span className="flex-shrink mx-4 text-muted-foreground text-xs">
            Or
          </span>
          <div className="flex-grow border-t border-muted-foreground"></div>
        </div>
        <Button
          value="google"
          className="w-full hover:bg-zinc-100 bg-zinc-200 p-1.5 border  flex justify-center items-center gap-2 rounded-md mt-2 text-zinc-900 font-medium text-sm"
          onClick={onButtonClick}
        >
          <img src="/brands/google.svg" alt="GitHub" className="h-4 w-4" />
          Continue with Google
        </Button>
        <Button
          value="github"
          className="w-full hover:bg-zinc-100 bg-zinc-200 p-1.5 border   flex justify-center items-center gap-2 rounded-md mt-2 text-zinc-900 font-medium text-sm  "
          onClick={onButtonClick}
        >
          <img src="/brands/github.svg" alt="GitHub" className="h-4 w-4" />
          Continue with GitHub
        </Button>
      </CardFooter>
    </Card>
  );
}
