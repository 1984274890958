import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PasswordInput } from "../SecretInput";
import { type AuthCardProps } from "./AuthCard";
import { useState } from "react";

const formSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters"),
});

export default function PasswordForm({ formOption }: AuthCardProps) {
  const [error, setError] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setError(null);
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    let res = new Response();
    let enableSignIn = formOption === "login";

    if (formOption === "signup") {
      res = await fetch("/api/auth/signup", {
        method: "post",
        body: formData,
      });

      if (res.status === 200) {
        enableSignIn = true;
      }
    }

    if (enableSignIn) {
      res = await fetch("/api/auth/signin", {
        method: "post",
        body: formData,
      });
    }

    if (res.status != 200 && res.status != 302) {
      const err = "Error: " + (await res.text());
      if (err.includes("rate limit")) {
        setError(
          "Email rate limit exceeded (please use some other method to sign up) :(",
        );
      } else {
        setError(err);
      }
    }

    if (res.status === 200 || res.status === 302) {
      const text = await res.text();
      window.location.replace(text);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <span className="text-red-500 text-sm ">{error}</span>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <PasswordInput autoComplete="complete-password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" variant="default" className="w-full">
          {formOption === "signup" ? "Sign Up" : "Log In"}
        </Button>
      </form>
    </Form>
  );
}
