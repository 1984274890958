import { cn } from "@/lib/utils";
import { Eye, EyeOff } from "lucide-react";
import { forwardRef, useState } from "react";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

// secret input / password input
const Input = forwardRef<HTMLInputElement, InputProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          className={cn(
            "flex h-10 w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500  disabled:cursor-not-allowed disabled:opacity-50 ",
            className,
          )}
          ref={ref}
          {...props}
        />
        <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3 text-gray-400">
          {showPassword ? (
            <Eye onClick={togglePasswordVisibility} className="h-4 w-4" />
          ) : (
            <EyeOff onClick={togglePasswordVisibility} className="h-4 w-4" />
          )}
        </div>
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input as PasswordInput };
